(function ($) {
  Drupal.behaviors.productColorFamilyV1 = {
    /**
     * Flag to ensure this JS is only attached once.
     * @type {boolean}
     */
    attached: false,

    /**
     * Shows only the shades in the shade picker in the color family.
     * @param {string} colorFamily - the color family name.
     * @param {Object} context - the DOM scope, usually document.
     */
    showColorFamily: function (colorFamily, context) {
      var defaultFamily = $('.js-product-color-family--v1', context).data('color-family-all-label');
      var $shadesContainer = $('.js-product-shades-container', context);
      var $allShades = $('.js-product-shade', $shadesContainer);

      $allShades.removeClass('hidden');

      // Don't do this loop if the color family is the default (normally "All").
      if (colorFamily !== defaultFamily) {
        $allShades.each(function () {
          var $shade = $(this);
          var shadeFamily = $shade.data('family');

          // some of the shadefamily has empty value in .net and it should hide during filter
          if (shadeFamily != null) {
            shadeFamily = shadeFamily.replace(/\s+/g, '').toLowerCase().split(',');
            if (shadeFamily.indexOf(colorFamily.toLowerCase()) === -1) {
              $shade.addClass('hidden'); // Hide any shade not in the color family.
            }
          }
        });
      }

      $shadesContainer.trigger('product.colorFamilySelect');
    },

    /**
     * Adds all the color families found in the product's shades
     * to the DOM for the user to click on.
     * @param {Object} context - the DOM scope, usually document.
     */
    setupColorFamilyLinks: function (context) {
      var colorFamiliesSoFar = [];
      var $allShades = $('.js-product-shade', context);
      var $colorFamilyContainer = $('.js-product-color-family--v1', context);
      var $colorFamilyLink = $(
        '<a href="#" class="product-color-family__link link-stub-black js-product-color-family-link"></a>'
      );

      $allShades.each(function () {
        var $shade = $(this);
        var shadeFamily = $shade.data('family');
        var familyName = null;
        var familyNameWithSpace = null;

        if (!!shadeFamily) {
          shadeFamily = shadeFamily.replace(/\s+/g, '').split(',');
          shadeFamily.forEach(function (family) {
            familyName = family.trim().toLowerCase();
            familyNameWithSpace = familyName.split('_').join(' ');
            foundIndex = _.findIndex(colorFamiliesSoFar, { familyName: familyName });

            // If there is a color family defined for this shade, and
            // it hasn't already been added to the families array, create
            // a new entry with value 1.
            if (!!familyName && foundIndex === -1) {
              colorFamiliesSoFar.push({
                familyName: familyName,
                familyNameWithSpace: familyNameWithSpace,
                shadeCount: 1
              });
              // Otherwise increment the familyName's shadeCount.
            } else {
              colorFamiliesSoFar[foundIndex]['shadeCount']++;
            }
          });
        }
      });

      // We now have an Object of colorFamily and shadeCount, in colorFamiliesSoFar.
      // Now sort them by shadeCount value, and use reverse because _.orderby is unavailable.
      var sortedColorFamilies = _.sortBy(colorFamiliesSoFar, function (o) {
        return o.shadeCount;
      });

      sortedColorFamilies.reverse();

      // Add all the color family links to the DOM.
      $.each(sortedColorFamilies, function (index, value) {
        $colorFamilyLink
          .clone()
          .attr('data-color-family', value.familyName)
          .text(value.familyNameWithSpace)
          .appendTo($colorFamilyContainer);
      });

      // Hide the color family links if there are no color families at all,
      // and don't bother setting up the click events.
      if (colorFamiliesSoFar.length < 2) {
        $colorFamilyContainer.addClass('hidden');

        return;
      } else {
        var allLabel = $colorFamilyContainer.data('color-family-all-label');
        var $colorFamilyAllLink = $colorFamilyLink.clone().addClass('link-stub-black--active').text(allLabel);

        $colorFamilyAllLink.prependTo($colorFamilyContainer);
      }

      // Makes each color family link load just the appropriate shades.
      $('.js-product-color-family-link').on('click touchstart', function (event) {
        event.preventDefault();
        var $this = $(this);
        var $product = $this.closest('.js-product');
        var filterColorFamily;
        var filterColorFamilyLink = $(this).attr('data-color-family');

        if (typeof filterColorFamilyLink !== typeof undefined && filterColorFamilyLink !== false) {
          filterColorFamily = filterColorFamilyLink;
        } else {
          filterColorFamily = $this.text();
        }
        $product.trigger('product.showColorFamily', [filterColorFamily]);
      });
    },

    /**
     * Sets up events for this template.
     * @type {Object} context - the DOM scope, usually document.
     */
    setEvents: function (context) {
      var that = this;

      // On quickshop load, set up the color family links.
      $(document).on('product.init', '.js-quickshop', function () {
        that.setupColorFamilyLinks($(this));
      });

      // support for url router
      $(document).on('product.showColorFamily', '.js-product', function (e, colorFamily) {
        var $product = $(this);
        var $links = $product.find('.js-product-color-family-link');

        $links.removeClass('link-stub-black--active');
        $links.each(function () {
          var displayColorFamily;
          var selectedColorFamily = $(this).attr('data-color-family');

          if (typeof selectedColorFamily !== typeof undefined && selectedColorFamily !== false) {
            displayColorFamily = selectedColorFamily;
          } else {
            displayColorFamily = $(this).text();
          }
          if (displayColorFamily === colorFamily) {
            $(this).addClass('link-stub-black--active');
          }
        });

        that.showColorFamily(colorFamily, context);

        var prodData = prodcat.data.getProduct($product.data('product-id'));
        var $sppLinks = $('.js-spp-link', $product);

        if (colorFamily !== $links.first().text()) {
          $sppLinks.each(function () {
            $(this).attr('href', prodData.url + '#/family/' + colorFamily);
          });

          if ($product.hasClass('product-full')) {
            history.pushState({}, colorFamily, '#/family/' + colorFamily);
          }
        } else {
          $sppLinks.each(function () {
            $(this).attr('href', prodData.url);
          });

          if ($product.hasClass('product-full')) {
            history.pushState({}, colorFamily, '#');
          }
        }
      });

      $(document).trigger('product.colorFamilyLoaded');
    },

    /**
     * Calls all methods for setting up this template's JS.
     * Separated out in case it must be called after certain triggers too.
     * @param {Object} context - the DOM scope, usually document.
     */
    init: function (context) {
      this.setupColorFamilyLinks(context);
      this.setEvents(context);
    },

    /**
     * Standard method for attaching the template's JS.
     * @param {Object} context - the DOM scope, usually document.
     * @param {Object} settings - useful collection of site info.
     */
    attach: function (context, settings) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      this.init(context);
    }
  };
})(jQuery);
